import "#/datasource/brainmaps/frontend";
import "#/datasource/brainmaps/register_credentials_provider";
import "#/datasource/brainmaps/register_default";
import "#/datasource/boss/frontend";
import "#/datasource/boss/register_credentials_provider";
import "#/datasource/boss/register_default";
import "#/datasource/deepzoom/frontend";
import "#/datasource/deepzoom/register_default";
import "#/datasource/dvid/frontend";
import "#/datasource/dvid/register_credentials_provider";
import "#/datasource/dvid/register_default";
import "#/datasource/render/frontend";
import "#/datasource/render/register_default";
import "#/datasource/precomputed/frontend";
import "#/datasource/precomputed/register_default";
import "#/datasource/nifti/frontend";
import "#/datasource/nifti/register_default";
import "#/datasource/n5/frontend";
import "#/datasource/n5/register_default";
import "#/datasource/zarr/frontend";
import "#/datasource/zarr/register_default";
import "#/datasource/vtk/frontend";
import "#/datasource/vtk/register_default";
import "#/datasource/obj/frontend";
import "#/datasource/obj/register_default";
import "#/datasource/ngauth/register_credentials_provider";
import "#/datasource/middleauth/register_credentials_provider";
import "#/datasource/nggraph/frontend";
import "#/datasource/nggraph/register_default";
import "#/datasource/graphene/frontend";
import "#/datasource/graphene/register_default";
import "#/image_user_layer";
import "#/segmentation_user_layer";
import "#/single_mesh_user_layer";
import "#/annotation/user_layer";
import "/home/runner/work/neuroglancer/neuroglancer/src/main.ts";
